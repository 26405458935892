import styled from 'styled-components';

const Heading = styled.h1`
  font-family: 'Roboto', sans-serif !important;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 33px;
  text-align: center;
  padding: 36px 28px;
  color: #FFFFFF;
  max-width: 650px;
  margin: 0 auto 36px auto;

  @media(min-width: 769px) {
    padding: 60px 0;
    font-size: 36px;
    line-height: 42px;
  }
`

export { Heading };