import styled from 'styled-components';

const Row = styled.div`
  border: 1px solid #4300D2;
  padding: 24px 12px;
  border-top-left-radius: ${props => (props.first ? '8px' : '0px')};
  border-top-right-radius: ${props => (props.first ? '8px' : '0px')};
  border-bottom-right-radius: ${props => (props.last ? '8px' : '0px')};
  border-bottom-left-radius: ${props => (props.last ? '8px' : '0px')};
  display: flex;
  align-items: center;
`

const Field = styled.p`
  font-family: 'Roboto', sans-serif !important;
  font-style: normal;
  font-weight:${props => props.plus ? '600' : '300'};
  font-size: ${props => props.plus ? '14px' : '11px'};;
  line-height: 14px;
  width: ${props => props.plus ? '12px' : 'calc(33% - 12px)'};
  display: ${props => props.button ? 'none' : 'flex'};
  align-items: center;
  color: #FFFFFF;
  margin:${props => (props.price) ? '0 8px 0 0' : '0'}; ;
  text-transform: capitalize;
  justify-content:${props => (props.plus || props.price) ? 'flex-end' : 'flex-start'};
  cursor: ${props => (props.plus || props.button) ? 'pointer' : 'cursor'};
  line-height: 1.1;
  padding-right: ${props => props.indexName ? '12px' : '6px'};
  padding-left: ${props => props.cap ? '8px' : '0'};


  @media(min-width: 460px) {
    font-size: 16px;
  }

  @media(min-width: 550px) {
    display: ${props => (props.plus ? 'none' : 'flex')};
    font-size: ${props => (props.button) ? '14px' : '16px'};
    font-weight: ${props => (props.button) ? 'bold' : 'lighter'};;
    justify-content:${props => ((props.button || props.price) ? 'flex-end' : 'flex-start')};
    color:${props => ((props.button) ? '#4300D2' : '#FFFFF')};
    width: calc(25% - 12px);
    padding-left: 0;
  }

  @media(min-width: 769px) {
    font-size: ${props => (props.button) ? '14px' : '18px'};
    justify-content:${props => ((props.button || props.price) ? 'flex-end' : (props.cap ? 'center' : 'flex-start'))};

  }
`

const Img = styled.img`
  max-width: 12px;
  min-width: 12px;
  max-height: 12px;
  min-height: 12px;
  margin: 0;
  margin-right: 5px;
  border-radius: 100%;

  @media(min-width: 460px) {
    margin-right: 8px;
    max-width: 16px;
    min-width: 16px;
    max-height: 16px;
    min-height: 16px;
  }

  @media(min-width: 769px) {
    margin-right: 12px;
    max-width: 24px;
    min-width: 24px;
    max-height: 24px;
    min-height: 24px;
  }
`

const StyledSpan = styled.span`
  color: #FFFF;
  padding: 0 4px 8px 0;
  letter-spacing: 2px;
`

export { Row, Field, Img, StyledSpan }