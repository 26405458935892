import React from 'react';
import { Heading } from './Components';

const Hero = () => {
  return (
    <main>
      <Heading>
        Yieldscanner Research presents its series of Crypto Indices structured by Asset Class.
      </Heading>
    </main>
  )
};

export default Hero;