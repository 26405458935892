import React from 'react';
import { Section, Header, Label } from './Components';
import IndexRow from '../IndexRow/IndexRow.jsx';
import { v4 as uuidv4 } from 'uuid';

const IndicesGrid = ({ indices = [] }) => {

  return (
    <Section>
      <Header>
        <Label first> Index Name </Label>
        <Label middle> Market Cap (M) </Label>
        <Label last> Price </Label>
      </Header>
      {
        indices.map((index = {}, i) => {
          return (
            <IndexRow
              index={index}
              last={i === indices.length - 1}
              first={i === 0}
              key={uuidv4()}
            />
          )
        })
      }
    </Section>
  )
};

export default IndicesGrid;