import React, { useState, useEffect } from 'react';
import { Row, Field, Img, StyledSpan } from './Components';
import { Link } from 'gatsby';
import styles from './IndexRow.module.css';
import slugify from 'slugify';
import { formatPrice, getLatestIndexData } from '../../utils';

const IndexRow = ({ index = {}, first = false, last = false }) => {

  const indexData = index.node || {};

  const { _id: indexId, name: indexName } = index.node || {};

  const initialMarketCap = indexData.market_cap ? (indexData.market_cap / 1000000) : null;

  const initialPrice = formatPrice(indexData.price);

  const initialMarketCapFormatted = formatPrice(initialMarketCap);

  const [marketCap, setMarketCap] = useState(initialMarketCapFormatted);

  const [price, setPrice] = useState(initialPrice);

  useEffect(() => {
    const updatePrice = async (indexId) => {
      if (indexId) {
        try {
          const { price, market_cap } = await getLatestIndexData(indexId)
          setPrice(formatPrice(price))
          setMarketCap(formatPrice(market_cap / 1e6))
        } catch (e) {
          //
        }
      }
    }
    updatePrice(indexId)
    const ID = setInterval(() => { updatePrice(indexId) }, 1000 * 60 * 5)
    return () => {
      clearInterval(ID)
    }
  }, [indexId])

  const indexPageUrl = `/indices/${indexName && slugify(indexName, { lower: true })}`

  return (
    <Row
      first={first}
      last={last}
    >
      <Img src={indexData.logo && indexData.logo.url} alt={indexName} />
      <Field indexName> {(indexData.name || '').toLowerCase()} </Field>
      <Field cap> {marketCap} </Field>
      <Field price> {price} </Field>
      <Field plus>
        <Link to={indexPageUrl} className={styles.anchor}>
          +
        </Link>
      </Field>
      <Field button>
        <Link to={indexPageUrl} className={styles.anchor}>
          <StyledSpan> ... </StyledSpan>
          <span> view details </span>
        </Link>
      </Field>

    </Row>
  )

}


export default IndexRow;