import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from '../components/Hero/Hero.jsx';
import IndicesGrid from '../components/IndicesGrid/IndicesGrid.jsx';
import { graphql } from "gatsby"
import styled from 'styled-components';


const IndexPage = ({ data }) => {

  let indices = [];

  try {
    indices = data.allIndices.edges;
  } catch (e) {
    indices = []
  }

  const H2 = styled.h2`
    color: #FFFFFF;
    font-family: 'Roboto', sans-serif !important;
    max-width: 690px;
    margin: 0 auto 36px auto;
    text-align: center;
    line-height: 140%;
    font-size: 16px;

    @media(min-width: 769px) {
      font-size: 20px;
    }
  `

  return (
    <Layout>
      <SEO title="Home" />
      <Hero />
      <IndicesGrid indices={indices} />
      <H2>
        The COINDEX SERIES is a set of indices developed to deliver gauge of the main crypto asset classes, reflecting the behaviour of the main Mineable (CoindexM) and Non-Mineable crypto assets (NoCOINDEX).
      </H2>
    </Layout>
  )
}

export default IndexPage;

export const query = graphql`
  query IndicesQuery {
    allIndices( filter: { name: { ne: "dummy content" } } ) {
      edges {
        node {
          _id
          logo {
            url
          }
          market_cap
          name
          price
          last_updated
        }
      }
    }
  }
`

